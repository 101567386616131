<template>
  <div>
    <el-form :inline="true">
      <!-- 列表区域 -->
      <el-form-item>
        <el-input
          v-model="queryinfo.query"
          :clearable="true"
          @clear="getlist"
          style="width:240px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getlist"
          ></el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" :data="loglist" border style="width: 100%;">
      <el-table-column type="expand" label="详情">
        <template slot-scope="{ row }">
          <el-form label-position="left" :inline="true" class="loginlogform">
            <el-form-item label="经度：">
              <span>{{ row.lat || '暂无' }}</span>
            </el-form-item>
            <el-form-item label="纬度：">
              <span>{{ row.lon || '暂无' }}</span>
            </el-form-item>
            <el-form-item label="国家代码：">
              <span>{{ row.address || '暂无' }}</span>
            </el-form-item>
            <el-form-item label="城市：">
              <span>暂无</span>
            </el-form-item>
            <el-form-item label="地区：">
              <span>暂无</span>
            </el-form-item>
            <el-form-item label="邮编：">
              <span>暂无</span>
            </el-form-item>
            <el-form-item label="时区：">
              <span>暂无</span>
            </el-form-item>
            <el-form-item label="ISP：">
              <span>暂无</span>
            </el-form-item>
            <el-form-item label="连接类型：">
              <span>暂无</span>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="#"></el-table-column>
      <el-table-column prop="username" :label="$t('yhm')"></el-table-column>
      <el-table-column
        prop="log_type"
        :label="$t('yh.denglu') + '/' + $t('yh.dengchu')"
      >
        <template slot-scope="{ row }">
          <span v-if="row.log_type === '0'">{{ $t('yh.denglu') }}</span>
          <span v-else>{{ $t('yh.dengchu') }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="ip" :label="$t('yh.ipdz')"></el-table-column>
      <el-table-column
        prop="platform"
        :label="$t('yh.yhdlpt')"
      ></el-table-column>
      <el-table-column prop="address" :label="$t('yh.gjdm')"></el-table-column>
      <el-table-column prop="lat" :label="$t('yh.jingdu')">
        <template slot-scope="{ row }">{{ row.lat || '暂无' }}</template>
      </el-table-column>
      <el-table-column prop="lon" :label="$t('yh.weidu')"
        ><template slot-scope="{ row }">{{ row.lon || '暂无' }}</template>
      </el-table-column>
      <el-table-column
        prop="createtime"
        :label="$t('yh.czsj')"
        :formatter="dateFormat"
      ></el-table-column>
    </el-table>
    <!-- 分页区域 -->
    <el-pagination
      :hide-on-single-page="total <= 10"
      background
      layout="prev, pager, next, total"
      :page-size="10"
      :total="total"
      :current-page="queryinfo.page"
      @current-change="changepage"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 获取用户列表的参数对象
      queryinfo: {
        query: '',
        page: 1
      },
      loglist: [],
      total: 0,
      loading: true
    }
  },
  created() {
    // 请求用户列表数据
    this.getlist()
  },
  methods: {
    // 获取用户列表
    async getlist() {
      this.loading = true
      const { data } = await this.$http.get('/admin/user/loginlog', {
        params: this.queryinfo
      })
      if (data) {
        if (data.code !== 200) {
          this.$message.error(this.getlang(data.msg))
        } else {
          this.loglist = data.data.list
          this.total = parseInt(data.data.count)
        }
      }
      this.loading = false
    },
    // 分页事件
    changepage(val) {
      this.queryinfo.page = val
      this.getlist()
    },
    // 格式化时间
    dateFormat(row) {
      const time = row.createtime
      const dt = new Date(parseInt(time) * 1000)
      const y = dt.getFullYear()
      const m = (dt.getMonth() + 1 + '').padStart(2, '0')
      const d = (dt.getDate() + '').padStart(2, '0')
      const hh = (dt.getHours() + '').padStart(2, '0')
      const mm = (dt.getMinutes() + '').padStart(2, '0')
      const ss = (dt.getSeconds() + '').padStart(2, '0')
      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
    }
  }
}
</script>

<style lang="less">
.el-pagination {
  margin-top: 15px;
  text-align: center;
}
.loginlogform .el-form-item {
  width: 19%;
  margin-bottom: 0;
}
.loginlogform .el-form-item__label {
  font-weight: 600 !important;
  color: #99a9bf !important;
}
</style>
